import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  tabsRoot: {
    minHeight: 20,
  },
  tabsIndicator: {
    background: palette.primary.light,
  },
  tabsScrollButton: {
    '&:empty': {
      width: 0,
    },
  },
  tabRoot: {
    minHeight: 20,
    minWidth: 0,
    padding: 10,
    background: palette.grey.main,
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  tabSelected: {
    background: palette.lightGrey.light,
  },
}));
