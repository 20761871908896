import {
  AVAILABLE_GRIDS,
  MAP_PROPERTIES,
  TILE_LAYER_COORDINATE_POSTFIX,
  TILE_LAYER_STATIC_PREFIX,
} from 'constants/map';
import { API_URL } from 'common/config';
import { DATE_FORMATS, EOL, SHORT_TIME_UNITS } from 'constants/common';
import {
  convertCoordinateToString,
  getPolygonPositionsFromGeometry,
} from 'helpers/map';
import { formatDate } from 'helpers/date';
import { RUN_TYPE } from 'constants/zones';

/**
 * Prepare grids from existing fields
 * @param  {Object} zone
 * @return {Array}
 */
export const getGridsData = (zone) =>
  AVAILABLE_GRIDS.reduce((acc, { id, name }) => {
    const grids = zone[id];

    if (grids && grids.length > 0) {
      acc.push({ id, name });
    }
    return acc;
  }, []);

/**
 * Prepare zone grids to map layer
 *
 * @param {Object} grid
 * @param {Object} layer
 * @return {Object}
 */
export const prepareZoneLayer = (grid, layer) => {
  const tilePath = layer.tilesPath.split('/');
  const tile = tilePath[tilePath.length - 1];

  // convert `EXAMPLE test-name` to `example`
  const name = layer.name
    .split('-')[0]
    .toLowerCase()
    .split(' ')[0];

  // avoid double slash
  const cleanedPrefix = API_URL.endsWith('/')
    ? TILE_LAYER_STATIC_PREFIX.replace(/^\//, '')
    : TILE_LAYER_STATIC_PREFIX;

  return {
    id: grid.id,
    title: grid.name,
    name: tile && name ? tile : name,
    tile: `${API_URL}${cleanedPrefix}${encodeURIComponent(
      tile
    )}/${TILE_LAYER_COORDINATE_POSTFIX}`,
  };
};

/**
 * Prepare zone layers from existing fields
 *
 * @param  {Object} zone
 * @return {Array}
 */
export const getZoneLayers = (zone) =>
  AVAILABLE_GRIDS.reduce((acc, grid) => {
    const [layer] = zone[grid.id] || [];

    if (!layer || layer.length === 0) {
      return acc;
    }

    acc.push(prepareZoneLayer(grid, layer));

    return acc;
  }, []);

/**
 * Convert temporal resolution to string format
 * @param {String} temporalResolution
 * @param {Number} digits
 * @return {string}
 */
export const convertTemporalResolution = (temporalResolution, digits = 0) => {
  if (temporalResolution >= 1) {
    return `${parseInt(temporalResolution, 10).toFixed(digits)}${
      SHORT_TIME_UNITS.hours
    }`;
  }
  const temporalResolutionMinutes = temporalResolution * 60;

  return temporalResolutionMinutes >= 1
    ? `${temporalResolutionMinutes}${SHORT_TIME_UNITS.minutes}`
    : `${temporalResolutionMinutes}${SHORT_TIME_UNITS.seconds}`;
};

/**
 * Replace ';' ins string on spec symbol
 * @param {String} value
 * @param {String} symbol
 * @return {string}
 */
const replaceDividerOnSpecSymbol = (value = '', symbol = EOL) =>
  value.replace(';', symbol);

/**
 * Prepare zone grid info for dataset table
 * @param {Object} grid
 * @return {{
 *  maxCoordinates: {lng: string, lat: string},
 *  minCoordinates: {lng: string, lat: string},
 *  name: string,
 *  notes: string,
 *  level: string,
 *  origin: string,
 *  dateEnd: string,
 *  dateBegin: string,
 *  timeStep: string,
 *  isRunNested: boolean,
 *  spatialExtent: string,
 *  samplingPeriod: string,
 *  spatialResolution: string,
 *  spatialResolutionUnits: string
 *  }}
 */
export const prepareGridForDatasetTable = (grid) => {
  const [minCoordinates, maxCoordinates] = getPolygonPositionsFromGeometry(
    grid.geom
  );

  return {
    name: grid.name,
    notes: replaceDividerOnSpecSymbol(grid.notes),
    level: replaceDividerOnSpecSymbol(grid.level),
    samplingPeriod: replaceDividerOnSpecSymbol(grid.samplingPeriod),
    origin: replaceDividerOnSpecSymbol(grid.origin),
    minCoordinates: {
      lat: convertCoordinateToString(
        minCoordinates.lat,
        MAP_PROPERTIES.latitude
      ),
      lng: convertCoordinateToString(
        minCoordinates.lng,
        MAP_PROPERTIES.longitude
      ),
    },
    maxCoordinates: {
      lat: convertCoordinateToString(
        maxCoordinates.lat,
        MAP_PROPERTIES.latitude
      ),
      lng: convertCoordinateToString(
        maxCoordinates.lng,
        MAP_PROPERTIES.longitude
      ),
    },
    dateBegin: formatDate(grid.dateBegin, DATE_FORMATS.shortMonthFormat),
    dateEnd: formatDate(grid.dateEnd, DATE_FORMATS.shortMonthFormat),
    spatialResolution: grid.spatialResolution || '-',
    spatialResolutionUnits: grid.spatialResolutionUnits || '',
    timeStep: convertTemporalResolution(
      grid.displayedTimeStep || grid.timeStep
    ),
    spatialExtent: grid.spatialExtent,
    isRunNested: grid.kind === RUN_TYPE.nested,
  };
};

/**
 * Get zone dataset information
 * @param {Object} zone
 * @return {Array}
 */
export const getZoneDataSet = (zone) =>
  AVAILABLE_GRIDS.reduce((acc, grid) => {
    const [data] = zone[grid.id] || [];

    if (data && data.origin) {
      acc.push({ ...data, field: grid.name });
    }

    return acc;
  }, []);

/**
 * Get buoys by zone grid and validation
 * @param {Array} buoys
 * @param {Object} zoneGrid
 * @param {String} sensor
 * @return {Array}
 */
export const getBuoysByZoneRuns = ({ buoys, zoneGrid, sensor }) => {
  const { zoneRunNames, zoneRunIds } = zoneGrid.runs.reduce(
    (acc, { name, id }) => {
      acc.zoneRunIds.push(id);
      acc.zoneRunNames.push(name);

      return acc;
    },
    {
      zoneRunIds: [],
      zoneRunNames: [],
    }
  );

  return buoys.filter(({ runs, sensors = [], validations = [] }) => {
    const isZoneRuns = runs.some(({ name }) => zoneRunNames.includes(name));
    const hasSensor = sensors.includes(sensor);
    const hasValidation = validations.some(({ run }) =>
      zoneRunIds.includes(run.id)
    );

    return isZoneRuns && hasSensor && hasValidation;
  });
};
