import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ComparisonTabs from 'components/common/ComparisonTabs';
import { getFieldsWithStatisticsData } from 'helpers/satelliteComparison';
import { BUOYS_MEASUREMENTS_METADATA_TAB } from 'constants/buoys';
import ComparisonAgainstSatelliteRows from 'components/projects/ComparisonAgainstSatelliteRows';
import BuoyMetadata from 'components/buoys/BuoyMetadata';
import ScatterPlot from 'components/common/graphs/ScatterPlot';
import { getPreparedScatterPlotData } from 'helpers/graphs/scatterPlot';

import { useStyles } from './styles';

/**
 * Component with metadata and charts.
 * @param {Object} statistics
 * @param {Object} buoy
 * @return {jsx}
 */
const ComparisonAgainstMeasurements = ({ statistics, buoy }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { statisticsByTab, tabs } = useMemo(() => {
    const statsByTab = getFieldsWithStatisticsData(statistics);

    return {
      statisticsByTab: statsByTab,
      tabs: [
        BUOYS_MEASUREMENTS_METADATA_TAB,
        ...Object.keys(statsByTab).sort(),
      ],
    };
  }, [statistics]);
  const [tab, setTab] = useState(tabs[0]);
  const data = statisticsByTab[tab];
  const scatterData =
    tab !== BUOYS_MEASUREMENTS_METADATA_TAB &&
    data &&
    getPreparedScatterPlotData(data);

  return (
    <div>
      {tabs.length ? (
        <>
          <Paper variant="outlined" className={classes.tabs}>
            <ComparisonTabs items={tabs} onChange={setTab} value={tab} />
          </Paper>
          {tab === BUOYS_MEASUREMENTS_METADATA_TAB ? (
            <BuoyMetadata buoy={buoy} />
          ) : (
            <>
              <ScatterPlot commonData={scatterData} />
              <ComparisonAgainstSatelliteRows rows={data.rows} />
            </>
          )}
        </>
      ) : (
        <Typography align="center">
          {t('points.visualizationDataIsEmpty')}
        </Typography>
      )}
    </div>
  );
};

ComparisonAgainstMeasurements.propTypes = {
  statistics: PropTypes.object.isRequired,
  buoy: PropTypes.shape({
    name: PropTypes.string,
    centre: PropTypes.string,
    geom: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    sensors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default memo(ComparisonAgainstMeasurements);
