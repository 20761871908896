import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  header: {
    margin: '10px 0',
  },
  pointsInfo: {
    color: palette.grey.middle,
    margin: '10px',
  },
  descriptionHeader: {
    margin: '20px 0 10px',
  },
}));
