import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  notificationRoot: {
    top: '20vh',
    whiteSpace: 'pre',
    '& .MuiSnackbarContent-root': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  },
});
