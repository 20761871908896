import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  overviewText: {
    fontSize: 12,
    fontWeight: 700,
  },
  overviewHelpText: {
    background: alpha(palette.primary.light, 0.5),
    padding: '4px 8px',
    borderBottom: '1px solid',
    borderBottomColor: palette.white.main,
  },
}));
