import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectBuoyById } from 'ducks/buoys/selectors';
import OverviewBuoyInfo from 'components/buoys/OverviewBuoyInfo';

/**
 * Container for get buoy data from storage.
 * @param {Number} buoyId
 * @return {jsx}
 */
const OverviewBuoyInfoContainer = ({ buoyId }) => {
  const buoy = useSelector((state) => selectBuoyById(state, buoyId));

  return <OverviewBuoyInfo buoy={buoy} />;
};

OverviewBuoyInfoContainer.propTypes = {
  buoyId: PropTypes.number.isRequired,
};

export default memo(OverviewBuoyInfoContainer);
