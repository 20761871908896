import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  convertCoordinateToString,
  getPositionFromGeometry,
} from 'helpers/map';
import { MAP_PROPERTIES } from 'constants/map';
import { formatDate } from 'helpers/date';
import { DATE_FORMATS, EOL } from 'constants/common';
import {
  OverviewCard,
  OverviewRow,
  OverviewLabelBlock,
} from 'components/common/OverviewCard';

import { useStyles } from './styles';

/**
 * OverviewBuoyInfo - draw the table with buoy information.
 * @param {Object} buoy
 * @return {jsx}
 */
const OverviewBuoyInfo = ({ buoy }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [latitude, longitude] = getPositionFromGeometry(buoy.geom);
  const buoyOverview = {
    lat: convertCoordinateToString(latitude, MAP_PROPERTIES.latitude),
    lng: convertCoordinateToString(longitude, MAP_PROPERTIES.longitude),
    dateBegin: formatDate(buoy.dateBegin, DATE_FORMATS.shortMonthFormat),
    dateEnd: formatDate(buoy.dateEnd, DATE_FORMATS.shortMonthFormat),
    type: buoy.sensors.join(', '),
    origin: buoy.centre,
    name: buoy.name,
  };

  return (
    <OverviewCard>
      <OverviewRow>{buoyOverview.name}</OverviewRow>
      <OverviewLabelBlock label={t('buoys.coordinates')}>
        <Typography className={classes.overviewText}>
          {t('buoys.latitude', { lat: buoyOverview.lat })}
          {EOL}
          {t('buoys.longitude', { lng: buoyOverview.lng })}
        </Typography>
      </OverviewLabelBlock>
      <OverviewLabelBlock label={t('buoys.periodCovered')}>
        <Typography className={classes.overviewText}>
          {buoyOverview.dateBegin}
          {EOL}
          {buoyOverview.dateEnd}
        </Typography>
      </OverviewLabelBlock>
      <OverviewLabelBlock label={t('buoys.type')}>
        {buoyOverview.type}
      </OverviewLabelBlock>
      <OverviewLabelBlock label={t('buoys.origin')}>
        {buoyOverview.origin}
      </OverviewLabelBlock>
      <OverviewRow className={classes.overviewHelpText}>
        {t('buoys.selectHelpText')}
      </OverviewRow>
    </OverviewCard>
  );
};

OverviewBuoyInfo.propTypes = {
  buoy: PropTypes.shape({
    name: PropTypes.string.isRequired,
    centre: PropTypes.string.isRequired,
    geom: PropTypes.string.isRequired,
    dateBegin: PropTypes.string.isRequired,
    dateEnd: PropTypes.string.isRequired,
    sensors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default memo(OverviewBuoyInfo);
