import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

/**
 * Small info block
 * @param {String} className
 * @param {React.ReactNode} children
 * @param {Object} props
 * @returns {jsx}
 */
export const OverviewBlock = memo(({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Grid
      xs={6}
      className={classNames(classes.overviewBlock, className)}
      alignItems="center"
      justifyContent="center"
      container
      item
      {...props}
    >
      {children}
    </Grid>
  );
});

OverviewBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * Small info block for divide information and label
 * @param {String} label
 * @param {String} className
 * @param {React.ReactNode} children
 * @returns {jsx}
 */
export const OverviewLabelBlock = memo(({ label, children, className }) => {
  const classes = useStyles();

  return (
    <Grid
      xs={6}
      item
      container
      wrap="nowrap"
      className={classNames(classes.overviewLegend, className)}
    >
      <OverviewBlock className={classes.overviewLabel}>{label}</OverviewBlock>
      <OverviewBlock className={classes.overviewContent}>
        {children}
      </OverviewBlock>
    </Grid>
  );
});

OverviewLabelBlock.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/**
 * Full size block with divider and label
 * @type {jsx}
 */
export const OverviewFullSizeLabelBlock = memo(
  ({ label, children, className }) => {
    const classes = useStyles();

    return (
      <Grid item container wrap="nowrap" className={className}>
        <OverviewBlock xs={3} className={classes.overviewFullSizeLegend}>
          {label}
        </OverviewBlock>
        <OverviewBlock
          xs={9}
          className={classes.overviewFullSizeContent}
          justifyContent="flex-start"
        >
          {children}
        </OverviewBlock>
      </Grid>
    );
  }
);

OverviewFullSizeLabelBlock.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/**
 * OverviewRow - full size row with information
 * @param {React.ReactNode} children
 * @param {String} className
 * @return {jsx}
 */
export const OverviewRow = memo(({ children, className }) => {
  const classes = useStyles();

  return (
    <Grid
      xs={12}
      item
      container
      justifyContent="center"
      className={classNames(classes.overviewRow, className)}
    >
      {children}
    </Grid>
  );
});

OverviewRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * OverviewCard - card, using a compounds component pattern for render information.
 * @param {React.ReactNode} children
 * @param {String} className
 * @return {jsx}
 */
export const OverviewCard = ({ children, className }) => (
  <Grid container wrap="wrap" className={className}>
    {children}
  </Grid>
);

OverviewCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
