import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  datasetInfo: {
    border: '5px solid',
    borderColor: alpha(palette.primary.main, 0.6),
    maxWidth: 620,
    margin: '28px 0',
  },
  datasetBlock: {
    '& > div': {
      padding: 10,
      fontSize: 13,
    },
    '&:last-child > div': {
      borderBottom: 'none',
    },
  },
}));
