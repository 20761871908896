import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  OverviewCard,
  OverviewRow,
  OverviewLabelBlock,
  OverviewFullSizeLabelBlock,
} from 'components/common/OverviewCard';
import { EOL } from 'constants/common';
import { prepareGridForDatasetTable } from 'helpers/zones';

import { useStyles } from './styles';

/**
 * GridDatasetInfo - component with dataset information
 * @param {Object} grid
 * @return {jsx}
 */
const GridDatasetInfo = ({ grid }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dataset = prepareGridForDatasetTable(grid);

  return (
    <OverviewCard className={classes.datasetInfo}>
      <OverviewRow>{t('dataset.title', dataset)}</OverviewRow>
      <OverviewLabelBlock
        label={t('dataset.spatialExtent')}
        className={classes.datasetBlock}
      >
        {t('dataset.coordinateRange', {
          min: dataset.minCoordinates.lat,
          max: dataset.maxCoordinates.lat,
        })}
        {EOL}
        {t('dataset.coordinateRange', {
          min: dataset.minCoordinates.lng,
          max: dataset.maxCoordinates.lng,
        })}
      </OverviewLabelBlock>
      <OverviewLabelBlock
        label={
          dataset.isRunNested
            ? t('dataset.minimumPeriodCovered')
            : t('dataset.periodCovered')
        }
        className={classes.datasetBlock}
      >
        {dataset.dateBegin}
        {EOL}
        {dataset.dateEnd}
      </OverviewLabelBlock>
      <OverviewLabelBlock
        label={t('dataset.spatialResolution')}
        className={classes.datasetBlock}
      >
        {dataset.spatialResolution}
        {dataset.spatialResolutionUnits}
      </OverviewLabelBlock>
      <OverviewLabelBlock
        label={t('dataset.temporalResolution')}
        className={classes.datasetBlock}
      >
        {dataset.timeStep}
      </OverviewLabelBlock>
      {dataset.origin && (
        <OverviewFullSizeLabelBlock
          label={t('dataset.origin')}
          className={classes.datasetBlock}
        >
          {dataset.origin}
        </OverviewFullSizeLabelBlock>
      )}
      {dataset.samplingPeriod && (
        <OverviewFullSizeLabelBlock
          label={t('dataset.samplingPeriod')}
          className={classes.datasetBlock}
        >
          {dataset.samplingPeriod}
        </OverviewFullSizeLabelBlock>
      )}
      {dataset.level && (
        <OverviewFullSizeLabelBlock
          label={t('dataset.level')}
          className={classes.datasetBlock}
        >
          {dataset.level}
        </OverviewFullSizeLabelBlock>
      )}
      {dataset.notes && (
        <OverviewFullSizeLabelBlock
          label={t('dataset.notes')}
          className={classes.datasetBlock}
        >
          {dataset.notes}
        </OverviewFullSizeLabelBlock>
      )}
    </OverviewCard>
  );
};

GridDatasetInfo.propTypes = {
  grid: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default React.memo(GridDatasetInfo);
