import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';
import ComparisonAgainstMeasurements from 'components/buoys/ComparisonAgainstMeasurements';
import ComparisonModalControls from 'components/common/ComparisonModalControls';

import { useStyles } from './styles';

/**
 * Modal with information about buoy.
 * @param {Function} onClose
 * @param {String} downloadLink
 * @param {Object} statistics
 * @param {Object} buoy
 * @return {jsx}
 */
const ComparisonAgainstMeasurementsModal = ({ onClose, statistics, buoy }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BaseModal
      title={t('buoys.comparisonAgainstMeasurementsTitle')}
      onClose={onClose}
      modalClass={classes.comparisonAgainstResultModal}
      controls={<ComparisonModalControls onClose={onClose} disableDownload />}
    >
      <ComparisonAgainstMeasurements statistics={statistics} buoy={buoy} />
    </BaseModal>
  );
};

ComparisonAgainstMeasurementsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  statistics: PropTypes.object.isRequired,
  buoy: PropTypes.shape({
    name: PropTypes.string,
    centre: PropTypes.string,
    geom: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    sensors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default memo(ComparisonAgainstMeasurementsModal);
