import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ palette, navbarHeight, projectMenuHeight, baseSideBarWidth }) => ({
    dataValidationContainer: {
      position: 'fixed',
      top: navbarHeight + projectMenuHeight,
      zIndex: 1101,
      right: 0,
      left: 0,
      bottom: 0,
    },
    sideBarContainer: {
      width: baseSideBarWidth,
    },
    dataValidationSideBar: {
      padding: '20px',
      paddingBottom: 0,
      boxShadow: `0 3px 5px 0 ${palette.primary.main}`,
    },
    contentContainer: {
      width: `calc(100% - ${baseSideBarWidth}px)`,
      height: `100%`,
      overflowX: 'auto',
      padding: 15,
    },
    mapContainer: {
      height: 600,
      width: '100%',
    },
  })
);
