import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import Template from 'components/common/Template';

import { useStyles } from './styles';

/**
 * Description about zone rendering.
 * @param {Object} zone
 * @param {React.ReactNode} children
 * @return {jsx}
 */
const ZoneInfo = ({ zone, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Typography variant="h5" align="center" className={classes.header}>
        {zone.name}
      </Typography>
      <Typography align="center" className={classes.pointsInfo}>
        {t('dataValidation.pointsInfo')}
      </Typography>
      {children}
      {zone.description && (
        <>
          <Typography
            variant="h5"
            align="center"
            className={classes.descriptionHeader}
          >
            {t('dataValidation.environmentalConditions')}
          </Typography>
          <Template template={zone.description} />
        </>
      )}
    </Grid>
  );
};

ZoneInfo.propTypes = {
  zone: PropTypes.shape({
    description: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default React.memo(ZoneInfo);
