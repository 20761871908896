import { Validator } from 'helpers/Validator';
import { minLength, required, regexpMatch } from 'validators';
import {
  BLANK_FIELD_MESSAGE,
  ONLY_CHARACTERS_AND_NUMBER,
} from 'constants/errors';
import { PROJECT_NAME_REGEXP } from 'constants/regexp';

/**
 * validation rules
 */
export const validationRules = {
  projectName: new Validator([
    required(BLANK_FIELD_MESSAGE),
    minLength(3),
    regexpMatch(PROJECT_NAME_REGEXP, ONLY_CHARACTERS_AND_NUMBER),
  ]),
};
