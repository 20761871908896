import { isObject } from 'lodash';

/**
 * Get all tabs from statistics
 * @param {Object} statistics
 * @return {Object}
 */
export const getFieldsWithStatisticsData = (statistics) =>
  Object.entries(statistics).reduce((acc, [type, value]) => {
    if (isObject(value) && value.rows && value.varNames) {
      acc[type] = value;
    }
    return acc;
  }, {});
