import React, { memo } from 'react';
import { CaretLeft } from 'phosphor-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import CreateReactElement from '../CreateReactElement';

import { useStyles } from './styles';

/**
 * Create React element with responsive design (especially for table)
 * @param content - html content
 * @param classes - style classes
 */
const createReactElement = (content, classes) => {
  const isTable = content.includes('<table');
  const element = (
    <CreateReactElement
      is="div"
      element={content}
      className={classNames({
        [classes.table]: isTable,
      })}
    />
  );
  return isTable ? <div className="table-responsive">{element}</div> : element;
};

/**
 * DetailsPage component
 * @param contentBlocks - array of page content blocks
 */
const DetailsPage = ({ contentBlocks }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    // Navigate to the ROUTES.statistics route
    history.push(ROUTES.statistics); // Replace with your actual route
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <button type="button" className={classes.button} onClick={handleClick}>
          <CaretLeft size={20} /> BACK TO STATISTICS
        </button>

        {contentBlocks.map(({ title, content, titleTag }) =>
          !content ? (
            <CreateReactElement is={titleTag} element={title} key={title} />
          ) : (
            <div key={title}>{createReactElement(content, classes)}</div>
          )
        )}
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  contentBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(DetailsPage);
