import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import PropertiesList from 'components/points/PropertiesList';
import BaseSideBar from 'components/common/BaseSideBar';
import ZoneGridInfo from 'components/zones/ZoneGridInfo';
import ZoneInfo from 'components/zones/ZoneInfo';
import DataValidationMap from 'components/maps/DataValidationMap';
import { getBuoysByZoneRuns, getGridsData } from 'helpers/zones';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { toSnakeUpperCase } from 'helpers/camelizer';
import BuoysFeatureGroup from 'components/maps/BuoysFeatureGroup';
import BouyInfoPopup from 'components/popups/BouyInfoPopup';
import GridDatasetInfo from 'components/zones/GridDatasetInfo';
import { trackNumericalSimulations } from 'ducks/trackers/actions/datavalidation';

import { useStyles } from './styles';

/**
 * Render zone grid info or zone info
 * @param {Object} grid
 * @param {Object} zone
 * @param {React.ReactNode} children
 * @return {jsx}
 */
const DataValidationContent = ({ grid, zone, children }) =>
  grid ? (
    <ZoneGridInfo grid={grid}>{children}</ZoneGridInfo>
  ) : (
    <ZoneInfo zone={zone}>{children}</ZoneInfo>
  );

/**
 * DataValidationDashboard - component for render side bar with info and Map with descriptions
 * @param {Object} zone
 * @param {Object} project
 * @param {Array} buoys
 * @param {Function} onPointClick
 * @param {Function} onBuoyClick
 * @returns {jsx}
 */
const DataValidationDashboard = ({
  project,
  zone,
  buoys,
  onPointClick,
  onBuoyClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    urlQuery: { ns: numericalSimulation },
    setUrlQuery,
  } = useUrlQuery();

  const projectZones = useMemo(() => [zone], [zone]);
  const numericalSimulationsItems = useMemo(() => getGridsData(zone), [zone]);
  const upperSnakeKeyNumerical = useMemo(
    () => numericalSimulation && toSnakeUpperCase(numericalSimulation),
    [numericalSimulation]
  );

  const currentGrid = useMemo(() => get(zone, [numericalSimulation, 0]), [
    zone,
    numericalSimulation,
  ]);
  const gridBuoys = useMemo(() => {
    if (!upperSnakeKeyNumerical || !currentGrid?.runs) {
      return buoys;
    }

    return getBuoysByZoneRuns({
      buoys,
      zoneGrid: currentGrid,
      sensor: upperSnakeKeyNumerical,
    });
  }, [buoys, upperSnakeKeyNumerical, currentGrid]);

  const onSelectGrid = useCallback(
    ({ id }) => {
      setUrlQuery({ ns: id });
      dispatch(trackNumericalSimulations(id));
    },
    [setUrlQuery]
  );
  const clearSelectedGrid = useCallback(() => setUrlQuery({}), [setUrlQuery]);

  return (
    <Grid container className={classes.dataValidationContainer}>
      <Grid item className={classes.sideBarContainer}>
        <BaseSideBar sideBarClass={classes.dataValidationSideBar}>
          <PropertiesList
            title={t('dataValidation.titles.zone')}
            selectedId={zone.id}
            items={projectZones}
            onItemClick={clearSelectedGrid}
          />
          <PropertiesList
            title={t('dataValidation.titles.numericalSimulations')}
            items={numericalSimulationsItems}
            selectedId={numericalSimulation}
            onItemClick={onSelectGrid}
          />
        </BaseSideBar>
      </Grid>
      <Grid item className={classes.contentContainer}>
        <DataValidationContent grid={currentGrid} zone={zone}>
          <DataValidationMap
            project={project}
            grid={currentGrid}
            onPointClick={onPointClick}
            wrapperClass={classes.mapContainer}
          >
            {!numericalSimulation ? (
              <BuoysFeatureGroup buoys={gridBuoys}>
                <BouyInfoPopup />
              </BuoysFeatureGroup>
            ) : (
              <BuoysFeatureGroup
                buoys={gridBuoys}
                type={upperSnakeKeyNumerical}
                numericalSimulation={numericalSimulation}
                onClick={onBuoyClick}
              />
            )}
          </DataValidationMap>
          {currentGrid?.id && (
            <Grid container justifyContent="center">
              <GridDatasetInfo grid={currentGrid} />
            </Grid>
          )}
        </DataValidationContent>
      </Grid>
    </Grid>
  );
};

DataValidationDashboard.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  zone: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  buoys: PropTypes.array.isRequired,
  onPointClick: PropTypes.func.isRequired,
  onBuoyClick: PropTypes.func.isRequired,
};

export default React.memo(DataValidationDashboard);
