import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapPopup } from 'components/leaflet';
import {
  BaseMapPopup,
  BaseMapPopupContent,
} from 'components/common/BaseMapPopup';
import OverviewBuoyInfo from 'containers/buoys/OverviewBuoyInfo';
import { centeringMapByLatLng } from 'helpers/map';
import { DEFAULT_CENTERING_MAP_DELAY } from 'constants/map';
import { trackBuoySelection } from 'ducks/trackers/actions/datavalidation';

/**
 * Popup with buoy information
 * @param {Object} props
 * @constructor
 */
const BouyInfoPopup = (props) => {
  const [overviewBuoyId, setOverviewBuoyId] = useState();
  const dispatch = useDispatch();

  const openBuoyPopupHandler = useCallback(
    ({ popup: { _source: buoy, _map: map } }) => {
      const { id } = buoy.properties;
      dispatch(trackBuoySelection(id));

      // For centering after open popup
      setTimeout(centeringMapByLatLng, DEFAULT_CENTERING_MAP_DELAY, {
        map,
        latLng: buoy.getLatLng(),
      });
      setOverviewBuoyId(id);
    },
    [setOverviewBuoyId]
  );

  return (
    <MapPopup {...props} onOpen={openBuoyPopupHandler}>
      <BaseMapPopup>
        <BaseMapPopupContent>
          <OverviewBuoyInfo buoyId={overviewBuoyId} />
        </BaseMapPopupContent>
      </BaseMapPopup>
    </MapPopup>
  );
};

export default memo(BouyInfoPopup);
