import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  header: {
    margin: '10px 0',
  },
  descriptionHeader: {
    margin: '20px 0 10px',
  },
  dataUsageWarning: {
    margin: '20px 0 10px',
  },
});
