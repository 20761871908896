import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import Template from 'components/common/Template';
import { SUPPORT_EMAIL } from 'common/config';

import { useStyles } from './styles';

/**
 * Description about zone grid rendering.
 * @param {Object} grid
 * @param {React.ReactNode} children
 * @return {jsx}
 */
const ZoneGridInfo = ({ grid, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Typography variant="h5" align="center" className={classes.header}>
        {grid.name}
      </Typography>
      {children}
      {grid.description && (
        <>
          <Typography
            variant="h5"
            align="center"
            className={classes.descriptionHeader}
          >
            {t('headers.description')}
          </Typography>
          <Template template={grid.description} />
        </>
      )}
      <Typography
        variant="h5"
        align="center"
        className={classes.dataUsageWarning}
      >
        {t('dataValidation.dataUsageTitle')}
      </Typography>
      <Typography>
        {t('dataValidation.dataUsageWarningInfo', { email: SUPPORT_EMAIL })}
      </Typography>
    </Grid>
  );
};

ZoneGridInfo.propTypes = {
  grid: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default React.memo(ZoneGridInfo);
