import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  main: {
    margin: '20px 58px',
  },
  container: {
    maxWidth: 1200,
    margin: '20px auto',
  },
  btnContainer: {
    maxWidth: 1200,
    margin: '0 auto',
  },
  link: {
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'underline',
    padding: '10px 0',
  },
  content: {
    width: '100%',
  },
  button: {
    padding: '5px 10px',
    marginBottom: 8,
    fontSize: 14,
    background: '#FAFAFA',
    borderRadius: 10,
    border: '1px solid #E1E5E6',
    width: 'fit-content',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 0.3,
    '&:hover': {
      color: palette.white.main,
      background: '#525252',
    },
  },
  table: {
    '& table': {
      margin: '0 auto',
    },
    '& td, th': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      border: palette.table.border,
      textAlign: 'center',
      fontSize: '1.2em',
      padding: 5,
    },
    '& th': {
      color: palette.white.main,
      backgroundColor: palette.table.header,
      minWidth: 100,
    },
    '& td:first-child': {
      color: palette.white.main,
      backgroundColor: palette.table.firstColumn,
    },
    '& tr:nth-child(even)': {
      backgroundColor: palette.table.columnEven,
    },
    '& tr:nth-child(odd)': {
      backgroundColor: palette.table.columnOdd,
    },
  },
}));
