import {
  INVALID_EMAIL_MESSAGE,
  INVALID_REGEXP,
  INVALID_URL_MESSAGE,
  MAXLENGTH_ERROR_MESSAGE,
  MINLENTH_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  IS_NOT_NUMBER,
  INVALID_MIN_VALUE,
  INVALID_MAX_VALUE,
} from 'constants/errors';
import {
  EMAIL_REGEXP,
  URL_REGEXP,
  NUMBER_REGEXP,
  SPACE_REGEXP,
} from 'constants/regexp';

/**
 * validator creators to use with useValidation hook. Feel free to add new validator creators
 * validator is object with shape {{ message: 'some error message', validate: (val) => { here goes validation } }}
 * @note validator creator should always return validator with described shape. validate function should return bool
 * @note if u want to use templates in message, use {{ }} brackets
 * @see useValidation
 * @see Validator
 */

/**
 * min length validator creator
 * @param length - minimal allowed length
 * @param message - error message
 * @return {{ message: string, validate: (function(*): boolean) }} return validator
 */
export const minLength = (length, message = MINLENTH_ERROR_MESSAGE) => ({
  message: message.replace(/{{length}}/, length),
  validate: (value) => value.length >= length,
});

/**
 * max length validator
 * @param length - maximum allowed length
 * @param message - error message
 * @return {{message: string, validate: (function(*): boolean)}}
 */
export const maxLength = (length, message = MAXLENGTH_ERROR_MESSAGE) => ({
  message: message.replace(/{{length}}/, length),
  validate: (value) => value.length <= length,
});

/**
 * required validator. Checks if value is not empty
 * @param message - error message
 * @return {{message: string, validate: (function(*=): boolean)}}
 */
export const required = (message = REQUIRED_ERROR_MESSAGE) => ({
  message,
  validate: (value) => !!value,
});

/**
 * email validator. Checks if value matches email standard regexp. Consider empty value valid
 * @param message
 * @return {{message: string, validate: (function(*=): boolean)}}
 */
export const email = (message = INVALID_EMAIL_MESSAGE) => ({
  message,
  validate: (value) => !value || EMAIL_REGEXP.test(value),
});

/**
 * url validator. Checks if value matches url regexp. Consider empty value valid
 * @param message
 * @return {{message: string, validate: (function(*=): boolean)}}
 */
export const isUrl = (message = INVALID_URL_MESSAGE) => ({
  message,
  validate: (value) => !value || URL_REGEXP.test(value),
});

/**
 * regexp validator. Checks if value mathes given regexp.
 * @param regexp
 * @param message
 * @return {{message: string, validate: (function(*=): boolean)}}
 */
export const regexpMatch = (regexp, message = INVALID_REGEXP) => ({
  message,
  validate: (value) => regexp.test(value),
});

/**
 * url validator. Checks if value matches number regexp. Consider empty value valid
 * @param message
 * @example 123 is valid 123.123 is valid, 123,123 is invalid 1e8 is invalid
 * @return {{message: string, validate: (function(*=): boolean)}}
 */
export const isNumber = (message = IS_NOT_NUMBER) => ({
  message,
  validate: (value) => !`${value}` || NUMBER_REGEXP.test(`${value}`),
});

/**
 * space validator. Checks for at least one space. Consider empty value valid
 * @param message
 * @example project_name is valid, project name is invalid
 * @return {{message: string, validate: (function(*=): boolean)}}
 */
export const noSpace = (message = INVALID_REGEXP) => ({
  message,
  validate: (value) => !value || !SPACE_REGEXP.test(value),
});

/**
 * Uniq name validator. Search entity name in existing array.
 * @param {Array} existingNames
 * @param {String} message
 * @example existing names - ['John']. John - invalid, Ted - valid
 * @return {{ message: string, validate: (function(*=): boolean) }}
 */
export const uniqName = (existingNames = [], message = INVALID_REGEXP) => ({
  message,
  validate: (value) => !existingNames.includes(value),
});

/**
 * Validate min integer value.
 * @param {Number} minValue
 * @param {String|Function} [message]
 * @return {{ message: string, validate: (function(*): boolean) }}
 */
export const min = (minValue, message) => ({
  message: message || INVALID_MIN_VALUE.replace(/{{minValue}}/, minValue),
  validate: (value) => value > minValue,
});

/**
 * Validate max integer value
 * @param {Number} maxValue
 * @param {String|Function} [message]
 * @return {{ message: string, validate: (function(*): boolean) }}
 */
export const max = (maxValue, message) => ({
  message: message || INVALID_MAX_VALUE.replace(/{{maxValue}}/, maxValue),
  validate: (value) => value < maxValue,
});
