import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  overviewBlock: {
    display: 'flex',
    background: palette.secondary.middle,
    color: palette.white.main,
    fontWeight: 700,
    padding: '4px 8px',
    borderBottom: '1px solid',
    borderBottomColor: palette.white.main,
  },
  overviewLabel: {
    whiteSpace: 'pre-line',
    display: 'flex',
    fontSize: 12,
    borderRight: '1px solid',
    borderRightColor: palette.white.main,
    background: palette.secondary.middle,
    color: palette.white.main,
    fontWeight: 700,
  },
  overviewLegend: {
    '&:nth-child(even)': {
      borderRight: '1px solid',
      borderRightColor: palette.white.main,
    },
  },
  overviewFullSizeLegend: {
    whiteSpace: 'pre-line',
    color: palette.grey.middle,
    fontWeight: 'bolder',
    fontSize: 12,
    borderRight: '1px solid',
    borderRightColor: palette.white.main,
    background: alpha(palette.primary.light, 0.5),
  },
  overviewFullSizeContent: {
    whiteSpace: 'pre-line',
    color: palette.black.main,
    fontWeight: '400',
    fontSize: 12,
    background: alpha(palette.primary.light, 0.5),
  },
  overviewContent: {
    whiteSpace: 'pre-line',
    fontSize: 12,
    fontWeight: 700,
    color: palette.black.main,
    background: palette.lightGrey.main,
  },
  overviewRow: {
    color: palette.black.main,
    fontWeight: 700,
    background: palette.sandy.main,
    padding: '4px 8px',
    borderBottom: '1px solid',
    borderBottomColor: palette.white.main,
  },
}));
