import React, { memo, useCallback } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * Tabs for modal comparison against result
 * @param {Function} onChange
 * @param {String} value
 * @param {Array} items
 * @param {Object} props
 * @return {jsx}
 */
const ComparisonTabs = ({ value, onChange, items, ...props }) => {
  const classes = useStyles();

  const handleChange = useCallback((_, selected) => onChange(selected), [
    onChange,
  ]);

  return (
    <Tabs
      {...props}
      variant="scrollable"
      value={value}
      onChange={handleChange}
      classes={{
        scrollButtons: classes.tabsScrollButton,
        root: classes.tabsRoot,
        indicator: classes.tabsIndicator,
      }}
    >
      {items.map((label) => (
        <Tab
          value={label}
          label={label}
          key={label}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
        />
      ))}
    </Tabs>
  );
};

ComparisonTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default memo(ComparisonTabs);
