import { useLocation, useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import qs from 'qs';

/**
 * Hook for get and update url query.
 * Hook could've used only with React Router and inside routers context.
 *
 * @return {{setUrlQuery: Function, mergeUrlQuery: Function}}
 */
export function useUrlQuery() {
  const { search } = useLocation();
  const history = useHistory();

  const urlQuery = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const setUrlQuery = useCallback(
    (paramsOrFunc) => {
      const params =
        typeof paramsOrFunc === 'function'
          ? paramsOrFunc(urlQuery)
          : paramsOrFunc;

      if (!isEqual(params, urlQuery)) {
        history.push({ search: qs.stringify(params) });
      }
    },
    [history, urlQuery]
  );

  const mergeUrlQuery = useCallback(
    (newQuery) => setUrlQuery((query) => ({ ...query, ...newQuery })),
    [setUrlQuery]
  );

  return { urlQuery, setUrlQuery, mergeUrlQuery };
}
