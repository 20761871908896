import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';

import ComparisonTabs from 'components/common/ComparisonTabs';
import { getFieldsWithStatisticsData } from 'helpers/satelliteComparison';
import ScatterPlot from 'components/common/graphs/ScatterPlot';
import { getPreparedScatterPlotData } from 'helpers/graphs/scatterPlot';

import ComparisonAgainstSatelliteRows from '../ComparisonAgainstSatelliteRows';

import { useStyles } from './styles';

/**
 * Component for rendering tabs, charts and tabla about satellite information.
 * @param {Object} statistics
 * @return {jsx}
 */
const ComparisonAgainstSatelliteResult = ({ statistics }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { statisticsByTab, tabs } = useMemo(() => {
    const statsByTab = getFieldsWithStatisticsData(statistics);

    return {
      statisticsByTab: statsByTab,
      tabs: Object.keys(statsByTab).sort(),
    };
  }, [statistics]);
  const [tab, setTab] = useState(tabs[0]);
  const data = statisticsByTab[tab];
  const scatterData = data && getPreparedScatterPlotData(data);

  return (
    <div id="componentToDownload">
      {tabs.length ? (
        <>
          <Paper variant="outlined" className={classes.tabs}>
            <ComparisonTabs items={tabs} onChange={setTab} value={tab} />
          </Paper>
          <ScatterPlot commonData={scatterData} />
          <ComparisonAgainstSatelliteRows rows={data.rows} />
        </>
      ) : (
        <Typography align="center">
          {t('points.visualizationDataIsEmpty')}
        </Typography>
      )}
    </div>
  );
};

ComparisonAgainstSatelliteResult.propTypes = {
  statistics: PropTypes.object.isRequired,
};

export default memo(ComparisonAgainstSatelliteResult);
