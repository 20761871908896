import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { Plus } from 'phosphor-react';

import { useStyles } from './styles';

/**
 * Button wti person icon for invite collaborator
 * @param {Object} props
 * @param {String} className
 * @returns {jsx}
 */
const InviteCollaboratorButton = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.floatingButton}
      classes={{ label: classes.label }}
      startIcon={<Plus size={20} />}
      {...props}
    >
      Invite collaborators
    </Button>
  );
};

export default memo(InviteCollaboratorButton);
